import React from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ActivityDashboardColumns = (name) => {
  return (
    <>
      {name?.name ? (
        <h4 className="mt-4">
          Good Day! {name?.name} Your Activities Today!
        </h4>
      ) : (
        <h3 className="mt-4">What your team is doing !</h3>
      )}
      <Table>
        <thead>
          
          <tr>
             <Row>
                <Col><th>First Name</th></Col>
                
                <Col><th>Activity</th></Col>
                <Col><th>Notes</th></Col>
                <Col><th>Start Time</th></Col>
                
                <Col><th>End Time</th></Col>
                <Col><th>Status</th></Col>
                <Col><th>Date</th></Col>

                
                
                
                <Col></Col>
                </Row>
          </tr>
        </thead>
      </Table>
    </>
  );
};

export default ActivityDashboardColumns;
