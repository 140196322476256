import React from 'react'
import useCreateUser from '../hooks/useCreateUser';
import { Container } from "react-bootstrap";

const Load = (file,setFile) => {
    const {progress} = useCreateUser(file.file)
   return (
    <Container></Container>
  )
}

export default Load;