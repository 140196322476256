import React, { useState, useRef } from "react";
import { useAuthValue } from "../../AuthContext";
import Load from "../helper/Load";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import {Toast,Container} from "react-bootstrap";

const AddUser = () => {
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);

  const nameInputElement = useRef();
  const formRef = useRef();
  const [name, setName] = useState("");
  const { currentUser } = useAuthValue();
  const [selectedItem, setSelectedItem] = useState("");

  const formHandler = (e) => {
    e.preventDefault();
    if (selectedItem.length===0)
      return window.alert("Please enter username and department");
    const data = {
      Name: nameInputElement.current.value,
      id: currentUser.uid,
      time: new Date(),
      department: selectedItem,
    };
    setName(data);
    setShow(true)
    formRef.current.reset();
  };

  
  const items = ["UI/UX", "Business", "HR/Legal", "IT","Admin","Immigration","Accounting","Other","Gaming"];
  return (
    <Container>
      <Toast
        onClose={() => setShow(false)}
        position="middle-center"
        show={show}
        delay={3000}
        autohide
      >
        <Toast.Body>
          new user registered in {selectedItem} ! 
          {new Date().toLocaleTimeString("en-US")}
        </Toast.Body>
      </Toast>
      <Form ref={formRef} onSubmit={formHandler}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label> Username</Form.Label>
          <Form.Control
            required
            className="mb-3"
            ref={nameInputElement}
            type="text"
            placeholder="Enter Username"
          />
          <Dropdown className="mt-3" as={ButtonGroup}>
            <Button variant="success">Select the department</Button>
            <Dropdown.Toggle
              title="Tasks"
              variant="success"
              id="dropdown-basic"
            >
              {selectedItem}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {items.map((item, id) => (
                <Dropdown.Item
                  required
                  key={id}
                  onClick={() => setSelectedItem(item)}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Button className="mt-3" type="submit">
          Submit
        </Button>
      </Form>
      {name && <Load file={name} setFile={setFile} />}
    </Container>
  );
};

export default AddUser;
