import React, { useState, useEffect } from "react";
import { useAuthValue } from "../../AuthContext";
import ActivityDashboard from "./ActivityDashboard";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { collection, query, onSnapshot ,where} from "firebase/firestore";
import { doc, updateDoc, db, setDoc } from "../../firebase";
import Table from "react-bootstrap/Table";
import moment from "moment";

const UserSummary = () => {
  const [user, setUser] = useState(null);
  const { tempDoc, currentUser } = useAuthValue();
  const [clockIn, setClockIn] = useState([]);
  const daily1 = moment(new Date()).subtract(12, "hours")._d;

  const employees = tempDoc.filter((val) => val.department !== undefined);

  const result = employees.reduce(function (r, a) {
    r[a.department] = r[a.department] || [];
    r[a.department].push(a);
    return r;
  }, Object.create(null));
  const alternatingColor = ["#d9ffda", "#bffffc"];
  useEffect(() => {
    const activitiesRef = collection(db, "ClockIns");
    const q = query(activitiesRef,where("LoginTime", ">", daily1));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let activityArray = [];
      querySnapshot.forEach((doc) => {
        activityArray.push({ ...doc.data(), id: doc.id });
      });
      setClockIn(activityArray);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        {Object.entries(result).map(([key, value]) => {
          return (
            <Card style={{ width: "18rem" }} className="m-1">
              <ListGroup variant="flush">
                <ListGroup.Item
                  style={{
                    backgroundColor:
                      alternatingColor[key.length % alternatingColor.length],
                  }}
                >
                  {key}
                </ListGroup.Item>
                <>
                  {value.map((item, index) => (
                    <ListGroup.Item
                      className="d-flex justify-content-between"
                      action
                      onClick={() => setUser(item?.name)}
                      key={index}
                    >
                      {item.name}
                      <Badge bg="primary" pill>
                        view
                      </Badge>
                    </ListGroup.Item>
                  ))}
                </>
              </ListGroup>
            </Card>
          );
        })}
      </Container>
      {user ? (
        <Button
          size="sm"
          style={{ position: "absolute", right: "45%" }}
          onClick={() => setUser(null)}
        >
          Clear user
        </Button>
      ) : null}
      <Container className="mt-3">
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Clock In Time</th>
              </tr>
            </thead>
            {clockIn?.map((item) => (
              <tbody>
                <tr>
                  <td>{item.name}</td>
                  <td>{item?.LoginTime.toDate().toLocaleTimeString("en-US")}</td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      </Container>
      <ActivityDashboard user={user} admin={"admin"} />
    </>
  );
};

export default UserSummary;
