import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useAuthValue } from "../../AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import Offcanvas from "react-bootstrap/Offcanvas";

const Profile = (user) => {
  const { currentUser } = useAuthValue();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Profile
      </Button>
      <Offcanvas show={show} onHide={handleClose} backdrop="static">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Profile</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            <strong>Name: </strong>
            {user.user?.name}
          </p>
          <p>
            <strong>Department: </strong>
            {user.user?.department}
          </p>
          <p>
            <strong>Email: </strong>
            {currentUser?.email}
          </p>
          <p>
            <strong>Email verified: </strong>
            {`${currentUser?.emailVerified}`}
          </p>
          <Button onClick={() => signOut(auth)}>Sign Out</Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Profile;
