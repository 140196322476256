import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {serverTimestamp,query, getFirestore,getDocs, setDoc, doc,addDoc, collection,orderBy,updateDoc  } from "firebase/firestore";



// Your web app's Firebase configuration
// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDqYipZKIZdTbpn_tZOBMOxBfZEL_jBFfo",
//   authDomain: "fir-user-reg-auth-c09ad.firebaseapp.com",
//   projectId: "fir-user-reg-auth-c09ad",
//   storageBucket: "fir-user-reg-auth-c09ad.appspot.com",
//   messagingSenderId: "1046089604773",
//   appId: "1:1046089604773:web:6fd774648ed73ec081b74c"
// };


//In A Pulse Account
const firebaseConfig = {
  apiKey: "AIzaSyCfqYtmVM5xDU6dQbsPNP6gxmmMmGZmakI",
  authDomain: "task-tracker-61eda.firebaseapp.com",
  projectId: "task-tracker-61eda",
  storageBucket: "task-tracker-61eda.appspot.com",
  messagingSenderId: "714169057055",
  appId: "1:714169057055:web:36138e2b585297b21d7a2a"
};
//Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export  { getStorage,orderBy,serverTimestamp, ref, query,uploadBytesResumable,addDoc, getDownloadURL ,getFirestore,getDocs, setDoc, doc,updateDoc, collection}

export { auth };
