import React, { useState, useEffect } from "react";
import ActivityForm from "./ActivityForm";
import ActivityDashboard from "./ActivityDashboard";
import { doc, updateDoc, db, setDoc } from "../../firebase";
import Button from "react-bootstrap/Button";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import moment from "moment";


const UserPage = (user2) => {
  const user = user2.user;
  const [file, setFile] = useState(null);
  const [activity, setActivity] = useState([]);
  const daily1 = moment(new Date()).subtract(12, "hours")._d;

  const formHandler = async (event) => {
    event.preventDefault();
    const nameAndTime = user?.name + new Date().toISOString().split('T')[0];
    const frankDocRef = doc(db, "ClockIns", nameAndTime);
    setFile(Date.now());
    setDoc(frankDocRef, {
      LoginTime: new Date(),
      name: user?.name,
      department:user?.department
    });
  };


  useEffect(() => {
    const activitiesRef2 = collection(db, "ClockIns");
    const q2 = query(activitiesRef2,where("LoginTime",">",daily1));
    const unsub = onSnapshot(q2, (querySnapshot) => {
      let activityArray = [];
      querySnapshot.forEach((doc) => {
        activityArray.push(doc.data());
      });
      setActivity(activityArray);
    });
    return () => unsub();
  }, [activity.length]);

  let arr = activity.filter(val=> val?.name === user.name)
  
  return (
    <div>
      {arr.length === 0 ? (
        <Button onClick={formHandler}>Clock In</Button>
      ) : (
        <>
          <Button variant="warning">
            {arr[0]?.LoginTime.toDate().toLocaleTimeString("en-US")}
          </Button>
          <ActivityForm user={user} />
        </>
      )}
    </div>
  );
};

export default UserPage;
