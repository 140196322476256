import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { doc, updateDoc, db } from "../../firebase";
import { useAuthValue } from "../../AuthContext";
import useFirestore from "../hooks/useFirestore";
import { Toast, Container } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";

const ActivityDashboardItems = (value) => {
  const [show, setShow] = useState(false);
  const [modalshow, setModalShow] = useState(false);

  const person = value?.value;
  //  console.log(person.startTime.seconds)
  var a = moment(person.Tasks?.startTime.toDate());
  var b = moment(person.Tasks?.endTime?.toDate());
  var seconds = b.diff(a, "seconds") % 60;
  var minutes = b.diff(a, "minutes") % 60;
  var hours = b.diff(a, "hours") % 60;
  var time = hours + ":" + minutes + ":" + seconds;
  const time2 = time.toString();
  const { tempDoc: tempDoc2 } = useFirestore("Tasks");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function updateTime(id) {
    tempDoc2.forEach(function (item, index) {
      if (item.Tasks.id === id) {
        const updateRef = doc(db, "Tasks", item.Tasks.id);
        updateDoc(updateRef, {
          "Tasks.endTime": new Date(),
          "Tasks.duration": "Completed",
        });
        setShow(true);
      } else console.log();
    });
    // setFile((prevFile) => ({
    //   ...prevFile,
    //   ...prevFile,
    //   endTime: new Date(),
    // }))
    handleClose()
  }
  const alternatingColor = ["#d6d6d6", "#ebebeb"];
  return (
    <>
      <Container
        style={{
          height: "10%",
          width: "12%",
          position: "absolute",
          top: "5%",
          left: "10%",
          zIndex: -1,
        }}
      >
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Stop {person?.Tasks?.activity} ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => updateTime(person.Tasks?.id)}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
        <Toast
          onClose={() => setShow(false)}
          position="middle-center"
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Body>
            {person?.Tasks?.activity} finished at{" "}
            {new Date().toLocaleTimeString("en-US")}
          </Toast.Body>
        </Toast>
      </Container>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <Row
            //style={{backgroundColor:alternatingColor[Math.floor(Math.random() * alternatingColor.length)]}}
            >
              <Col>
                <td>{person.name}</td>
              </Col>

              <Col>
                <td>{person.Tasks?.activity}</td>
              </Col>
              <Col>
                <td>{person.Tasks?.notes}</td>
              </Col>
              <Col>
                <td>
                  {person.Tasks?.startTime.toDate().toLocaleTimeString("en-US")}
                </td>
              </Col>

              <Col>
                <td>
                  {person.Tasks?.endTime?.toDate().toLocaleTimeString("en-US")}
                </td>
              </Col>

              <Col style={person.Tasks?.endTime ? {} : { color: "red" }}>
                <td>
                  {person.Tasks?.endTime
                    ?.toDate()
                    .toLocaleTimeString("en-US") ? (
                    <>
                      Time taken :
                      <Badge bg="secondary">{time.toString()}</Badge>
                    </>
                  ) : (
                    "Ongoing"
                  )}
                </td>
              </Col>
              <Col>
                <td>
                  {new Intl.DateTimeFormat("en-US").format(
                    person.Tasks?.startTime?.toDate()
                  )}
                </td>
              </Col>

              <Col>
                {value?.user?.user?.user?.name &&
                person.Tasks?.endTime == null ? (
                  <Button onClick={handleShow}>
                    stop
                  </Button>
                ) : null}
              </Col>
            </Row>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ActivityDashboardItems;
