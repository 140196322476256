import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Profile from "./Components/Pages/Profile";
import Register from "./Components/Pages/Register";
import VerifyEmail from "./Components/Pages/VerifyEmail";
import Login from "./Components/Pages/Login";
import { useState, useEffect } from "react";
import { AuthProvider } from "./AuthContext";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import PrivateRoute from "./Components/helper/PrivateRoute";
import { Navigate } from "react-router-dom";
import AddUser from "./Components/Pages/AddUser";
import ActivityDashboard from "../src/Components/Pages/ActivityDashboard";
import ActivityForm from "../src/Components/Pages/ActivityForm";
import useFirestore from "../src/Components/hooks/useFirestore";
import AdminPage from "./Components/Pages/AdminPage";
import ResetPassword from "./Components/Pages/ResetPassword";
import UserPage from "./Components/Pages/UserPage";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);
  const { tempDoc } = useFirestore("employees");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, [currentUser]);
  const activeUser = tempDoc?.find((x) => x?.id === currentUser?.uid);
  return (
    <Router>
      <AuthProvider value={{ currentUser, timeActive, setTimeActive, tempDoc }}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <PrivateRoute>
             
                <Profile user={activeUser} />
                {activeUser && activeUser.name ? <></> : <AddUser />}
                {activeUser && activeUser.name && !activeUser?.isAdmin ? (
                  <>
                    <UserPage user={activeUser}/>
                    {/* <ActivityForm user={activeUser} />
                    <ActivityDashboard /> */}
                  </>
                ) : null}
                {activeUser?.isAdmin ? <AdminPage user={activeUser} /> : null}
               </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              !currentUser?.emailVerified ? (
                <Login />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/register"
            element={
              !currentUser?.emailVerified ? (
                <Register />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset" element={<ResetPassword/>} /> 
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
