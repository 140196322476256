import React from 'react';
import UserSummary from './UserSummary';
import { Container } from "react-bootstrap";

const AdminPage = activeUser => {
  return (
    <Container fluid>
      <Container style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom:"2%",
          backgroundColor:"#faf5f5"
        }}><h1>Admin Page</h1></Container>
      <UserSummary user={activeUser}/>
    </Container>
  );
}

export default AdminPage;
