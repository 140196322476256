import { useState, useEffect } from "react";
import {Button,Container,Form} from "react-bootstrap";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";

function ResetPassword() {

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const auth = getAuth();

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email);
    setMessage("Password reset email sent")
  }
 
  return (
    <Container  className="center">
    {!message ? 
    <Container className="resetPassword-main">
      <Form.Label>Email address</Form.Label>
          <Form.Control
            className="mb-3"
            type="email"
            value={email}
            required
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
      <Button className="resetBtn" type="button" onClick={triggerResetEmail}>send</Button>
    </Container>
    :<Container>
      {message}   <Button  className="m-1"      type="checkbox"
          variant="secondary"><Link to="/"> Go Back</Link></Button>
    </Container>}
    </Container>
  )
}

export default ResetPassword;