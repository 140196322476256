import React, { useState, useEffect, useRef, useCallback } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { useAuthValue } from "../../AuthContext";
import { doc, updateDoc, db, setDoc } from "../../firebase";
import ActivityDashboard from "./ActivityDashboard";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const ActivityForm = (user) => {
  const [file, setFile] = useState(null);
  const formRef = useRef();
  const items = [
    "Break",
    "Interview",
    "Officework",
    "In Meeting",
    "Washroom",
    "Project",
  ];
  const [selectedItem, setSelectedItem] = useState(null);
  const [manualTask, setmanualTask] = useState("");
  const [notes, setNotes] = useState("");

  const [show, setShow] = useState(false);

  //Just to check and compare the current activity
  // tempDoc2.forEach(function (item, index) {
  //   if (item.Tasks.user === user?.user.name && item.Tasks.endTime === null) {
  //     console.log("true");
  //   } else console.log("false");
  // });

  const formHandler = async (event) => {
    event.preventDefault();
    if (selectedItem && manualTask)
      return window.alert("You can select only one Task");
    const nameAndTime = user?.user?.name + Math.floor(Date.now() / 1000);
    const frankDocRef = doc(db, "Tasks", nameAndTime);
    const data = {
      user: user.user.name,
      startTime: new Date(),
      endTime: null,
      duration: "Ongoing",
      activity: selectedItem || manualTask,
      id: user.user.name + Math.floor(Date.now() / 1000),
      notes: notes,
    };
    if (
      !(!data.activity || /^\s*$/.test(data.activity)) &&
      data.activity != null
    ) {
      setFile(data);
      setDoc(frankDocRef, {
        user_id: user.user.id,
        name: data.user,
        Tasks: data,
        time: new Date(),
      });
    } else {
      alert("please fill form");
    }
    setShow(true);
    setSelectedItem(null);
    setmanualTask("");
    setNotes("");
  };

  return (
    <Container className="mb-4" style={{ minHeight: "120px" }}>
      <Form onSubmit={formHandler}>
      <Row>
      <Col xs={2}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Body>
            {file?.activity
              ? "started at" + new Date().toLocaleTimeString("en-US")
              : null}
            {}
          </Toast.Body>
        </Toast>
        </Col>
      
          <Col xs="auto">
            
            <Dropdown as={ButtonGroup}>
              <Button variant="success">Select a Task</Button>
              <Dropdown.Toggle
                title="Tasks"
                variant="success"
                id="dropdown-basic"
              >
                {selectedItem}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {items.map((item, id) => (
                  <Dropdown.Item key={id} onClick={() => setSelectedItem(item)}>
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="or type manually"
              value={manualTask}
              onChange={(e) => setmanualTask(e.target.value)}
              maxLength="15"
            />
          </Col>
          <Col>
            {" "}
            <Form.Control
              as="textarea"
              placeholder="type your notes here"
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              maxLength="50"
            />
          </Col>
          <Col>
            <Button variant="primary" type="submit">
              Start
            </Button>
          </Col>

        </Row>
      </Form>

      <ActivityDashboard user={user} />
    </Container>
  );
};

export default ActivityForm;
