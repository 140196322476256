import React, { useEffect, useState } from "react";
import { useAuthValue } from "../../AuthContext";
import ActivityDashboardColumns from "../helper/ActivityDashboardColumns";
import ActivityDashboardItems from "../helper/ActivityDashboardItems";
import useFirestore from "../hooks/useFirestore";
import moment from "moment";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import {Nav,Container} from "react-bootstrap";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  doc,
  updateDoc,
  deleteDoc,
  where,limit
} from "firebase/firestore";
import { db } from "../../firebase";
import { Button } from "react-bootstrap";
const ActivityDashboard = (user) => {
  const defaultdata = moment(new Date()).subtract(3, "months")._d;
  const monthly = moment(new Date()).subtract(1, "months")._d;
  const weekly = moment(new Date()).subtract(7, "days")._d;
  const daily = moment(new Date()).subtract(1, "hours")._d;
  const daily1 = moment(new Date()).subtract(12, "hours")._d;
  const hourly_5 = moment(new Date()).subtract(5, "hours")._d;

  const items = [
    { value: daily, name: "Last hour" },
    { value: daily1, name: "Today's" },
    { value: weekly, name: "Last week" },
    { value: monthly, name: "Last month" },
    { value: defaultdata, name: "Last 3 months" },
  ];
  const [data, setData] = useState(null);

  const [activity, setActivity] = useState([]);
  const [isOngoing, setIsOngoing] = useState([]);

  useEffect(() => {

      const activitiesRef = collection(db, "Tasks");
      const q = query(
        activitiesRef,
        where("time", ">", data ? data :  hourly_5),
        orderBy("time","desc"),limit(user.admin==="admin" ? 500:30)
      );
      const unsub =onSnapshot(q, (querySnapshot) => {
        let activityArray = [];
        querySnapshot.forEach((doc) => {
          activityArray.push({ ...doc.data(), id: doc.id });
        });
      //  console.log(activityArray)
        setActivity(activityArray);
      });
      return () => unsub();
   
  }, [data]);
  //console.log(activity)
  console.log(user?.user?.user?.name)
  useEffect(() => {
    async function fetchData() {
      const activitiesRef = collection(db, "Tasks");
      const q = query(
        activitiesRef,
        where("Tasks.duration", "==", "Ongoing"),
        where("name","==",user?.user?.user?.name)
      );
      const unsub = onSnapshot(q, (querySnapshot) => {
        let activityArray = [];
        querySnapshot.forEach((doc) => {
          activityArray.push({ ...doc.data(), id: doc.id });
        });
        setIsOngoing(activityArray);
      });
      return () => unsub();
    }
    fetchData();
  }, []);
  console.log(isOngoing)
  let arr = isOngoing.concat(activity);
  const unique = [...new Map(arr.map((m) => [m.id, m])).values()];
  //console.log(arr)
  let newArr = [];

  for (let i = activity.length - 1; i >= 0; i--) {
    newArr.push(activity[i]);
  }

  const userActivities = unique.filter(
    (val) => val.name === (user?.user?.user?.name || user.user)
  );
  //console.log(userActivities)


  return (
    <div fuild style={{backgroundColor:"#f2f7fc"}}>
      {user?.admin ? (
        <Nav className="justify-content-center mt-5"  variant="tabs">
          {items.map((item, id) => (
            <Nav.Item style={{backgroundColor:"#fff"}} key={id} onClick={() => setData(item.value)}>
              <Nav.Link>{item.name}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      ) : null}
      <ActivityDashboardColumns name={user?.user?.user?.name} />
      {(user?.user?.user?.name || user.user ? userActivities : newArr).map(
        (item, id) => (
          <ActivityDashboardItems user={user} key={id} value={item} />
        )
      )}
    </div>
  );
};

export default ActivityDashboard;


