import { db, getDocs, collection, query, orderBy } from "../../firebase";
import { useState } from "react";
import { useEffect } from "react";

const useFirestore = (collection1) => {
  const [tempDoc, setTempDoc] = useState([]);
  var d = new Date();
  var yesterday = d.setDate(d.getDate() - 1);
  let documents = [];
  useEffect(() => {
    async function fetchData() {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, collection1), orderBy("time", "desc"))
        );
        querySnapshot.forEach((doc) => {
          documents.push({ id: doc.id, ...doc.data() });
        });

        setTempDoc(documents);
      } catch (error) {
        console.log("error", error);
      }
    }
    fetchData();
  }, [collection1.length]);
  return { tempDoc };
};

export default useFirestore;
