import React, {useContext,useState} from 'react'
import useFirestore from './Components/hooks/useFirestore'

const AuthContext = React.createContext()

export function AuthProvider({children, value}) {
  const {tempDoc} = useFirestore('employees')
  const [employees,setEmployees] = useState(tempDoc)
 
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthValue(){
  return useContext(AuthContext)
}
