import { useState, useEffect } from 'react';
import {serverTimestamp, setDoc, doc, collection,ref,updateDoc, uploadBytesResumable,  getDownloadURL,db, storage } 
from "../../firebase"

const useCreateUser = (file) => {
  const [progress, setLoad] = useState(0);
  
  const DocRef = doc(db, "employees", file.Name);
  useEffect(() => {
    // references

setDoc(DocRef, {
      name: file.Name,
      id:file.id,
      time: new Date(),
      department: file.department
  });
 // To update age and favorite color:
}, [file]
);
return{progress}
}
export default useCreateUser;